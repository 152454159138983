var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading,"opacity":0.7,"blur":"4px"}},[_c('v-select',{attrs:{"reduce":function (item) { return item.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.users.filter(function (u) { return !u.isMe; }),"label":"text","placeholder":_vm.placeholder,"debounce":"200","clearable":false,"multiple":_vm.multiple},on:{"search":_vm.getUser},scopedSlots:_vm._u([(!_vm.multiple)?{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var text = ref.text;
var isOnline = ref.isOnline;
return [_c('div',{staticClass:"d-flex"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":_vm.getAvatar(avatar),"size":"30","badge":"","badge-variant":isOnline ? 'success' : 'secondary',"text":text.charAt(0),"variant":"light-primary"}}),_c('span',[_vm._v(" "+_vm._s(text))])],1)]}}:null,{key:"option",fn:function(ref){
var avatar = ref.avatar;
var text = ref.text;
var isOnline = ref.isOnline;
var value = ref.value;
return [_c('b-avatar',{staticClass:"mr-1",style:({color: _vm.isSelected(value) && 'black' }),attrs:{"src":_vm.getAvatar(avatar),"size":"30","badge":"","badge-variant":isOnline ? 'success' : 'secondary',"text":text.charAt(0),"variant":_vm.isSelected(value) ? 'warning' : 'light-primary'}}),_c('span',[_vm._v(" "+_vm._s(text))])]}}],null,true),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }