<template>
  <div>
    <filters
      v-model="form"
      :date-type="dateType"
      @timezone-changed="updateTimezone"
    />
    <b-tabs
      v-model="activeTab"
      pills
    >
      <b-tab
        :title="$t('Day')"
        lazy
      >
        <day-view
          class="pt-1"
          :date-range="dayDate"
          :user-id="getUserId"
          type="day"
          :time-zone="getTimeZone"
        />
      </b-tab>
      <b-tab
        :title="$t('Week')"
        lazy
      >
        <day-view
          class="pt-1"
          :date-range="dayWeek"
          :user-id="getUserId"
          type="week"
          :time-zone="getTimeZone"
        />
      </b-tab>
      <b-tab
        :title="$t('Month')"
        lazy
      >
        <day-view
          class="pt-1"
          :date-range="dayMonth"
          :user-id="getUserId"
          type="month"
          :time-zone="getTimeZone"
        />
      </b-tab>
      <b-tab
        :title="$t('Date Range')"
        lazy
      >
        <day-view
          class="pt-1"
          :date-range="dateRange"
          :user-id="getUserId"
          type="month"
          :time-zone="getTimeZone"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import Filters from '@/views/common/components/Filter.vue'
import { mapActions, mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import DayView from './Day.vue'

export default {
  components: {
    BTab,
    BTabs,
    Filters,
    DayView,
  },
  data() {
    return {
      form: {
        date: this.TODAY({ localTime: false }),
        time_zone: localStorage.timezone ?? 'Asia/Kathmandu',
        userId: this.$route.query.user_id ?? null,
        start_date: this.$route.query.start_date || this.TODAY({ localTime: false }),
        end_date: this.$route.query.end_date || this.TODAY({ localTime: false }),
      },
      activeTab: this.$route.query.start_date ? 3 : 0,
    }
  },
  computed: {
    ...mapGetters('project', ['GET_SELECTED_USER']),
    getUserId() {
      return this.form.userId
    },
    getTimeZone() {
      return this.form.time_zone
    },
    dayDate() {
      return {
        start: `${this.form.date} 00:00:00`,
        end: this.ADD_DAYS(this.form.date, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    dayWeek() {
      const interval = this.INTERVAL_IN_WEEKS(this.form.date)
      return {
        start: interval.start,
        end: interval.end,
      }
    },
    dayMonth() {
      const interval = this.INTERVAL_IN_MONTH(this.form.date)
      return {
        start: interval.start,
        end: interval.end,
      }
    },
    dateType() {
      return this.activeTab === 3 ? 'range' : 'single'
    },
    dateRange() {
      return {
        start: `${this.form.start_date} 00:00:00`,
        end: this.ADD_DAYS(this.form.end_date, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
  },
  watch: {
    form: {
      handler(newValue) {
        this.storeFilterQuery('screenshots-filter', { ...newValue })
      },
      deep: true,
    },
    GET_SELECTED_USER: {
      handler(val) {
        if (val && val !== this.form.userId) this.form.userId = val
      },
      immediate: true,
    },
    getUserId(val) {
      if (val) this.setSelectedUser(val)
    },
  },
  mounted() {
    if (this.$route.query.start_date && this.$route.query.start_date && !this.$route.query.user_id) {
      this.form.userId = getUserData().uuid
    }
  },
  methods: {
    ...mapActions('project', ['setSelectedUser']),
    updateTimezone() {
      if (this.isAfterTODAY(this.form.date)) this.form.date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.start_date)) this.form.start_date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.end_date)) this.form.end_date = this.TODAY({ localTime: false })
    },
  },
}
</script>
