<template>
  <b-card
    no-body
    @click="$emit('click')"
  >
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="0"
      v-bind="{controls: getSrc.length > 1 }"
      background="#ababab"
      img-width="1024"
      img-height="380"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="img of getSrc"
        :key="img"
        :img-src="img"
        style="object-fit: cover;"
      >
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            :src="img"
            @click="showPreview(img)"
          >
        </template>

      </b-carousel-slide>
    </b-carousel>
    <b-card-body class="border pl-1">
      <slot :total-monitor="getSrc.length" />
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'

export default {
  name: 'CardImage',
  components: {
    BCard,
    BCardBody,
    BCarousel,
    BCarouselSlide,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  computed: {
    getSrc() {
      return this.src ? this.src.split(',') : []
    },
  },
  methods: {
    showPreview(img) {
      this.$emit('showPreview', img)
    },
  },
}
</script>
<style scoped="">
    img {
      cursor: pointer;
    }
</style>
